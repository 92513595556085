'use strict'

export const backButton = {
  init: function(selector) {
    selector = selector ?? '.js-back-button'
    window.addEventListener('load', this.setup(selector))
  },
  setup: function(selector) {
    const buttons = document.querySelectorAll(selector)
    if (buttons.length > 0) {
      buttons.forEach(function(button){
        const referrer = document.referrer
        if (referrer) {
          button.style.display = null
          button.addEventListener('click', function() {
            history.back()
          })
        }
      })
    }
  }
}